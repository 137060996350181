import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import * as compensationTypesJSON from './compensation-type.json';
import { CompensationType } from '../domain/compensation-type';

@Injectable({
  providedIn: 'root',
})
export class CompensationTypeService {
  readonly compensationTypes$ = of(<CompensationType[]>JSON.parse(JSON.stringify(compensationTypesJSON)).default);
}
