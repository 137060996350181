import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProjectListItem } from '../../../projects/domain/projectList';

@Component({
  selector: 'app-project-radio-button',
  template: `
    <div class="fx fx__just--space-between fx__align--center fx-fill">
      <div class="fx fx--col">
        <span class="text--gray text-14-500">Hull | {{ project.hull }}</span>
        <div class="fx">
          <span class="text--light-gray text-14-400">Client: </span>
          <span class="text--gray text-14-500">{{ project.client }}</span>
          <span class="text--light-gray text-14-400">, Vessel: </span>
          <span class="text--gray text-14-500">{{ project.vessel }}</span>
        </div>
        <span class="text--light-gray text-12-400">{{ project.status }}</span>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectRadioButtonComponent {
  @Input() project: ProjectListItem;

  constructor() {
  }
}
