import { Component } from '@angular/core';
import { NotificationService } from './core/notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, map } from 'rxjs/operators';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { LoaderService } from './core/loader.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <ng-container *ngIf="notification$ | async"></ng-container>
  `,
})
export class AppComponent {
  title = 'baltec-gui';
  notification$ = this.notificationService.notification$.pipe(
    filter((notification) => notification && notification.message.length > 0),
    map((notification) => {
      this.snackBar.open(notification.message, notification.action, notification.configuration);
    }),
  );
  loading$ = this.loaderService.loading$;

  constructor(private notificationService: NotificationService,
              private snackBar: MatSnackBar,
              private router: Router,
              private loaderService: LoaderService) {
    router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loaderService.loading$.next(true);
      }
      else if (event instanceof RouteConfigLoadEnd) {
        this.loaderService.loading$.next(false);
      }
    });
  }
}
