import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface Notification {
  message: string;
  action?: string;
  configuration?: NotificationConfiguration;
}

interface NotificationConfiguration {
  duration?: number;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private defaultConfiguration: NotificationConfiguration = {
    duration: 2500,
  };

  private notificationSubject$ = new BehaviorSubject<Notification>(null);
  readonly notification$ = this.notificationSubject$.asObservable();

  setNotification(message: string, action?: string, configuration?: NotificationConfiguration): void {
    console.log(configuration);
    this.notificationSubject$.next({
      message,
      action,
      configuration: {
        ...this.defaultConfiguration,
        duration: configuration?.duration !== undefined ? configuration.duration : this.defaultConfiguration.duration,
      },
    });
  }
}
