import { Component } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { AssignEmployeeService } from '../../services/assign-employee.service';
import { map, take } from 'rxjs/operators';
import { EmployeeAssignStep } from '../../shared/assign-employee/employee-assign-step';
import { Router } from '@angular/router';
import { PhaseWithEmployees } from '../../projects/domain/phase-with-employees';
import { ProjectService } from '../../services/project.service';
import { NotificationService } from '../../../core/notification.service';

@Component({
  selector: 'app-assign-employee',
  template: `
    <ng-container [ngSwitch]="step$.asObservable() | async">
      <app-projects-step
        *ngSwitchCase="'projects'"
        (closeEmployeeAssign)="onCloseEmployeeAssign()"
        (projectSelected)="onProjectSelected($event)"
      ></app-projects-step>
      <app-phases-step
        *ngSwitchCase="'phases'"
        [isPreviousStep]="true"
        [isNextStep]="false"
        [projectId]="(project$ | async)?.id"
        [phases]="(project$ | async)?.phases"
        [employeeIds]="employeeIds$ | async"
        (closeEmployeeAssign)="onCloseEmployeeAssign()"
        (backToPreviousStep)="setStep('projects')"
        (phaseSelected)="onPhaseSelected($event)"
      ></app-phases-step>
    </ng-container>
  `,
})
export class AssignEmployeeFromEmployeesComponent {
  step$ = new BehaviorSubject<EmployeeAssignStep>('projects');

  project$ = this.assignEmployeeService.project$;
  phase$ = this.assignEmployeeService.phase$;
  employeeIds$ = this.assignEmployeeService.employeeIds$;

  constructor(
    private readonly assignEmployeeService: AssignEmployeeService,
    private readonly router: Router,
    private readonly projectService: ProjectService,
    private readonly notificationService: NotificationService,
  ) {}

  setStep(value: EmployeeAssignStep): void {
    this.step$.next(value);
  }

  onCloseEmployeeAssign(): void {
    this.router.navigate(['/main/employees']);
  }

  onProjectSelected(projectId: string): void {
    this.projectService.getProject(projectId)
      .pipe(take(1))
      .subscribe((project) => this.assignEmployeeService.setProject(project));
    this.setStep('phases');
  }

  onPhaseSelected(phase: PhaseWithEmployees): void {
    this.assignEmployeeService.setPhase(phase);

    combineLatest([this.project$, this.phase$, this.employeeIds$])
      .pipe(
        take(1),
        map(([project, phase, employeeIds]): [string, string, string[]] =>
          [project.id, phase.id, employeeIds]
        ),
      )
      .subscribe(([projectId, phaseId, employeeIds]) =>
        this.assignEmployeeToPhase(projectId, phaseId, employeeIds)
      );
  }

  private assignEmployeeToPhase(projectId: string, phaseId: string, employeeIds: string[]) {
    this.assignEmployeeService.assignEmployeeToPhase(<string[]>employeeIds, <string>projectId, <string>phaseId)
      .pipe(take(1))
      .subscribe(() => {
        this.projectService.refreshProject(projectId);
        this.notificationService.setNotification('Employee was added to project');
        this.onCloseEmployeeAssign();
      });
  }
}
