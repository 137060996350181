import { Injectable } from '@angular/core';
import { Profession } from '../main/employees/domain/profession';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProfessionService {
  readonly professions$ = this.httpClient.get<Profession[]>(`${ environment.api }/profession`).pipe(
    shareReplay(1),
  );

  constructor(private httpClient: HttpClient) { }
}
