import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EmployeeConflict } from '../../models/employee-conflict';

@Component({
  selector: 'app-assign-employee-conflicts',
  template: `
    <div class="py-16 bg-red-light fx fx--center fx__gap--8">
      <mat-icon color="warn" svgIcon="alert"></mat-icon>
      <span class="text--red-dark text-20-500">Conflicting project dates</span>
    </div>
    <div mat-dialog-content class="p-16">
      <ul>
        <li *ngFor="let conflict of conflicts">
          <div class="fx fx__align--center fx__gap--8">
            <span class="text-16-400 text&#45;&#45;gray"> {{ conflict.lastName }}</span>
            <span class="text-16-400 text&#45;&#45;gray"> {{ conflict.firstName }}</span>
            <span class="text&#45;&#45;gray text-14-500">Hull: {{ conflict.hull }}</span>
          </div>
        </li>
      </ul>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignEmployeeConflictsComponent {
  @Input() conflicts: EmployeeConflict[];
}
