import { Component, EventEmitter, Output } from '@angular/core';
import { ProjectService } from '../../../services/project.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-projects-step',
  template: `
    <div class="fx fx--col fx-fill">
      <header class="header text-center">
        <button mat-icon-button class="close-button" (click)="onEmployeeAssignClose()">
          <mat-icon svgIcon="close"></mat-icon>
        </button>
        <p class="text--gray text-20-500">To which project assign the selected employee(s)?</p>
      </header>

      <div class="bg-gray fx-grow">
        <div class="container-details">
          <mat-card>
            <mat-card-content>
              <form name="filter" [formGroup]="filterForm">
                <div class="search fx fx__align--center">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-icon matPrefix svgIcon="magnify"></mat-icon>
                    <mat-label>Search for a project</mat-label>
                    <input matInput #nameSearch type="text" formControlName="name">
                    <button
                      matSuffix
                      mat-icon-button
                      type="button"
                      class="close-btn"
                      *ngIf="nameSearch.value.length"
                      (click)="filterForm.get('name').setValue('')"
                    >
                      <mat-icon inline class="close" svgIcon="close"></mat-icon>
                    </button>
                  </mat-form-field>
                </div>
                <div class="pb-16">
                  <mat-checkbox formControlName="ongoingStatus" class="text--gray text-14-500" color="primary">
                    Display only projects with Recruitment Ongoing status
                  </mat-checkbox>
                </div>
              </form>
              <form [formGroup]="form">
                <mat-radio-group class="fx fx--col fx__gap--16" formControlName="projectId">
                  <mat-radio-button
                    color="primary"
                    *ngFor="let project of (projects$ | async)?.items as projects"
                    [value]="project.id"
                  >
                    <app-project-radio-button [project]="project"></app-project-radio-button>
                  </mat-radio-button>
                </mat-radio-group>
                <p class="text-center text--light-gray" *ngIf="!(projects$ | async)?.items.length">
                  No projects meeting the given criteria
                </p>
              </form>
              <div class="fx fx__just--end fx__align--center">
                <button mat-button [disabled]="!form.valid" (click)="onProjectSelect()">Next</button>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./projects-step.component.scss', '../employee-assign.scss'],
})
export class ProjectsStepComponent {
  @Output() closeEmployeeAssign = new EventEmitter<void>();
  @Output() projectSelected = new EventEmitter<string>();

  filterForm = new FormGroup({
    ongoingStatus: new FormControl<boolean>(true, { nonNullable: true }),
    name: new FormControl<string>('', { nonNullable: true }),
  });
  form = new FormGroup({
    projectId: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
  });

  projects$ = this.filterForm.valueChanges.pipe(
    startWith({ name: null, ongoingStatus: true }),
    map((formValue) => ({
      pageIndex: 0,
      pageSize: 8,
      statusId: formValue.ongoingStatus ? '2' : null,
      search: formValue.name ? formValue.name : null,
    })),
    switchMap((filters) => this.projectService.getProjectList(filters)),
  );

  constructor(private readonly projectService: ProjectService) {}

  onEmployeeAssignClose(): void {
    this.closeEmployeeAssign.emit();
  }

  onProjectSelect(): void {
    this.projectSelected.emit(this.form.getRawValue().projectId);
  }
}
