<div class="fx fx--col fx-fill">
  <header class="header text-center">
    <button mat-icon-button class="close-button" (click)="onEmployeeAssignClose()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <p class="text--gray text-20-500">Which employee to assign?</p>
  </header>

  <div class="bg-gray fx-grow">
    <div class="container-details">
      <mat-card>
        <mat-card-content>
          <form name="filter" [formGroup]="employeeFilterForm">
            <div class="fx fx__gap--16">
              <mat-form-field appearance="fill" class="w-100">
                <mat-icon matPrefix svgIcon="magnify"></mat-icon>
                <mat-label>Search for an employee</mat-label>
                <input matInput #nameSearch type="text" formControlName="search">
                <button
                  matSuffix
                  *ngIf="nameSearch.value.length"
                  mat-icon-button
                  type="button"
                  class="close-btn"
                  (click)="employeeFilterForm.get('search').setValue('')"
                >
                  <mat-icon svgIcon="close"></mat-icon>
                </button>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Profession</mat-label>
                <mat-select formControlName="professionId">
                  <mat-option [value]="null"></mat-option>
                  <mat-option
                    *ngFor="let profession of professions; trackBy: professionIdTracker"
                    [value]="profession.id"
                  >{{ profession.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <button
              mat-button
              class="text--light-gray"
              *ngIf="(moreFiltersVisible$ | async) === false; else showLessButton"
              (click)="moreFiltersVisible.next(true)"
            >
              show more
              <mat-icon svgIcon="menu-down"></mat-icon>
            </button>
            <ng-template #showLessButton>
              <button
                mat-button
                class="text--light-gray"
                (click)="moreFiltersVisible.next(false)"
              >
                show less
                <mat-icon svgIcon="menu-up"></mat-icon>
              </button>
            </ng-template>
            <ng-container *ngIf="moreFiltersVisible$ | async">
              <div class="fx fx__gap--16" formArrayName="ratings">
                <mat-form-field appearance="fill" *ngFor="let category of ratingCategories$ | async; index as i">
                  <mat-label>{{ category.name }}</mat-label>
                  <input
                    matInput
                    type="number"
                    step="0.1"
                    [formControlName]="i"
                    [min]="category.min"
                    [max]="category.max"
                  >
                  <span matTextSuffix>/{{ category.max }}</span>
                  <mat-error *ngIf="ratingControlsArray.at(i).hasError('min')">
                    Below range
                  </mat-error>
                  <mat-error *ngIf="ratingControlsArray.at(i).hasError('max')">
                    Above range
                  </mat-error>
                </mat-form-field>
              </div>
              <mat-checkbox color="primary" formControlName="availableOnly">
                show only available employees
              </mat-checkbox>
            </ng-container>
          </form>
          <div class="dialog__radio">
            <form [formGroup]="employeeForm">
              <mat-selection-list
                formControlName="employeeId"
                multiple="false"
                (selectionChange)="onEmployeeSelectionChange($event)"
              >
                <mat-list-option
                  togglePosition="before"
                  color="primary"
                  *ngFor="let employee of employees$ | async; trackBy: employeeIdTracker"
                  [value]="employee.id"
                >
                  <mat-icon matListItemIcon color="warn" svgIcon="alert" *ngIf="!employee.isAvailable"></mat-icon>
                  <span matListItemTitle>
                        {{ employee.firstName }} {{ employee.lastName }}
                    <ng-container *ngIf="employee.professionName">| {{ employee.professionName }}</ng-container>
                      </span>
                  <span matListItemLine class="text-14-500" *ngIf="employee.averageRatings">
                        <ng-container *ngFor="let rating of employee.averageRatings; trackBy: ratingCategoryIdTracker">
                          <span class="">{{ rating.categoryName }}: </span>
                          <span class="text-14-400">{{ rating.value }} </span>
                        </ng-container>
                      </span>
                </mat-list-option>
              </mat-selection-list>
              <p class="text-center text--light-gray" *ngIf="!(employees$ | async)?.length">
                No employees meeting the given criteria
              </p>
            </form>
          </div>

          <div class="fx fx__just--end fx__align--center">
            <button
              mat-button
              type="button"
              *ngIf="isPreviousStep"
              (click)="goBackToPreviousStep()"
            >
              Previous
            </button>
            <button
              mat-flat-button
              color="primary"
              [disabled]="employeeForm.invalid"
              (click)="onEmployeeSelect()"
            >
              Assign
            </button>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card *ngIf="(conflicts$ | async).length > 0">
        <app-assign-employee-conflicts
          [conflicts]="conflicts$ | async"
        ></app-assign-employee-conflicts>
      </mat-card>
    </div>
  </div>
</div>
