import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AssignEmployeeService } from '../../services/assign-employee.service';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AreEmployeesSelectedGuard implements CanActivate {
  constructor(private readonly assignEmployeeService: AssignEmployeeService, private readonly router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.assignEmployeeService.employeeIds$.pipe(
      take(1),
      map((ids) => ids.length ? true : this.router.parseUrl('main/employees/list')),
    );
  }
}
