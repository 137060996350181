import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Phase } from '../../../projects/domain/phase';

@Component({
  selector: 'app-phase-radio-button',
  template: `
    <div class="fx fx__just--space-between fx__align--center fx-fill">
      <div class="fx fx--col">
        <span class="text--light-gray text-12-500">PHASE {{ index + 1 }}</span>
        <span class="text--gray text-16-500">
          {{ phase.phaseName }}
          </span>
        <span class="text--gray text-12-400" *ngIf="phase.startDate">{{phase.startDate}} - {{phase.endDate}}</span>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhaseRadioButtonComponent {
  @Input() phase: Phase;
  @Input() index: number;
}
