import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { shareReplay } from 'rxjs/operators';
import { EmployeeAssessment, RatingCategory } from '../domain/employee-assessment';
import { EmployeeAssessmentForm } from '../domain/employee-assessment-form';

@Injectable({
  providedIn: 'root'
})
export class EmployeesAssessmentsService {
  readonly ratingCategories$ = this.http.get<RatingCategory[]>(`${ environment.api }/rating-categories`)
    .pipe(shareReplay(1));

  constructor(private readonly http: HttpClient) {}

  getEmployeesAssessments(projectId: string): Observable<EmployeeAssessment[]> {
    return this.http.get<EmployeeAssessment[]>(`${ environment.api }/project/${projectId}/assessments`);
  }

  addAssessment(projectId: string, employeeId: string, assessmentForm: EmployeeAssessmentForm): Observable<{}> {
    return this.http.post(
      `${ environment.api }/project/${ projectId }/employee/${ employeeId }/assessment`,
      assessmentForm
    );
  }

  removeAssessment(projectId: any, employeeId: string, assessmentId: string): Observable<{}> {
    return this.http.delete(
      `${ environment.api }/project/${ projectId }/employee/${ employeeId }/assessment/${ assessmentId }`
    );
  }

  importAssessmentData(projectId: string, assessmentFile: File): Observable<{ notImportedIds?: number[] }> {
    const formData = new FormData();
    formData.append('assessmentFile', assessmentFile);

    return this.http.post(`${ environment.api }/project/${ projectId }/assessment/import`, formData);
  }
}
