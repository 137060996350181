import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsLoggedGuard } from './is-logged-guard';
import {
  AssignEmployeeFromProjectComponent,
} from './main/projects/assign-employee/assign-employee-from-project.component';
import {
  AssignEmployeeFromEmployeesComponent,
} from './main/employees/assign-employee/assign-employee-from-employees.component';
import { AreEmployeesSelectedGuard } from './main/employees/assign-employee/are-employees-selected.guard';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  {
    path: 'main',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
    canActivate: [IsLoggedGuard],
  },
  {
    path: 'main/employees/details',
    loadChildren: () => import('./main/employees/details/details.module').then(m => m.DetailsModule),
    canActivate: [IsLoggedGuard],
  },
  {
    path: 'main/projects/details',
    loadChildren: () => import('./main/projects/details/details.module').then(m => m.DetailsModule),
    canActivate: [IsLoggedGuard],
  },
  {
    path: 'main/employees/assign-employees',
    component: AssignEmployeeFromEmployeesComponent,
    canActivate: [IsLoggedGuard, AreEmployeesSelectedGuard],
  },
  {
    path: 'main/projects/list/:projectId/assign-employees',
    component: AssignEmployeeFromProjectComponent,
    canActivate: [IsLoggedGuard],
  },
  {
    path: 'main/projects/project-details/:projectId/assign-employees',
    component: AssignEmployeeFromProjectComponent,
    canActivate: [IsLoggedGuard],
  },
  {
    path: '',
    redirectTo: '/main/employees/list',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
