import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Phase } from '../../../projects/domain/phase';
import { MatRadioChange } from '@angular/material/radio';
import { PhaseWithEmployees } from '../../../projects/domain/phase-with-employees';
import { BehaviorSubject } from 'rxjs';
import { EmployeeConflict } from '../../models/employee-conflict';
import { take } from 'rxjs/operators';
import { AssignEmployeeService } from '../../../services/assign-employee.service';

@Component({
  selector: 'app-phases-step',
  template: `
    <div class="fx fx--col fx-fill">
      <header class="header text-center">
        <button mat-icon-button class="close-button" (click)="onEmployeeAssignClose()">
          <mat-icon svgIcon="close"></mat-icon>
        </button>
        <p class="text--gray text-20-500">To which phase assign the employee(s)?</p>
      </header>

      <div class="bg-gray fx-grow">
        <div class="container-details">
          <mat-card>
            <mat-card-content>
              <form [formGroup]="form">
                <mat-radio-group
                  class="fx fx--col fx__gap--16"
                  formControlName="phase"
                  (change)="checkForConflicts($event)"
                >
                  <mat-radio-button
                    color="primary"
                    *ngFor="let phase of phases; index as i "
                    [value]="phase"
                  >
                    <app-phase-radio-button [phase]="phase" [index]="i"></app-phase-radio-button>
                  </mat-radio-button>
                </mat-radio-group>
                <p class="text-center text--light-gray" *ngIf="!phases.length">
                  No phases in project
                </p>
              </form>

              <div class="fx fx__just--end fx__align--center">
                <button mat-button *ngIf="isPreviousStep" (click)="goBackToPreviousStep()">Previous</button>
                <button
                  mat-button
                  *ngIf="isNextStep; else assign"
                  [disabled]="!form.valid"
                  (click)="onPhaseSelect()"
                >
                  Next
                </button>
                <ng-template #assign>
                  <button mat-flat-button color="primary" [disabled]="!form.valid" (click)="onPhaseSelect()">
                    Assign
                  </button>
                </ng-template>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card *ngIf="(conflicts$ | async).length > 0">
            <app-assign-employee-conflicts
              [conflicts]="conflicts$ | async"
            ></app-assign-employee-conflicts>
          </mat-card>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['../employee-assign.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhasesStepComponent {
  @Input() isPreviousStep: boolean;
  @Input() isNextStep: boolean;
  @Input() projectId: string;
  @Input() phases: Phase[];
  @Input() employeeIds: string[];

  @Output() closeEmployeeAssign = new EventEmitter<void>();
  @Output() backToPreviousStep = new EventEmitter<void>();
  @Output() phaseSelected = new EventEmitter<PhaseWithEmployees>();

  form = new FormGroup({
    phase: new FormControl<PhaseWithEmployees>(undefined, Validators.required),
  });

  readonly conflicts$ = new BehaviorSubject<EmployeeConflict[]>([]);

  constructor(private readonly assignEmployeeService: AssignEmployeeService) {}

  goBackToPreviousStep(): void {
    this.backToPreviousStep.emit();
  }

  onPhaseSelect(): void {
    this.phaseSelected.emit(this.form.getRawValue().phase);
  }

  onEmployeeAssignClose(): void {
    this.closeEmployeeAssign.emit();
  }

  checkForConflicts(event: MatRadioChange): void {
    if (!this.employeeIds) return;

    const phase = event.value;
    this.assignEmployeeService.checkConflictsWithProject(this.projectId, phase.id, this.employeeIds)
      .pipe(take(1))
      .subscribe({
        next: () => this.conflicts$.next([]),
        error: (error) => this.conflicts$.next(error.error),
      });
  }
}
